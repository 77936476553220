import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import styled from "styled-components";

const AllButFooter = styled.div`
    min-height: calc(100vh - 50vh);
`

export default function Layout(props) {
  return (
    <div>
      <Navbar />
      <AllButFooter>{props.children}</AllButFooter>
      <Footer />
      <div
        className="fb-customerchat"
        attribution="install_email"
        page_id={`${process.env.GATSBY_MESSENGER_ID}`}
      ></div>
    </div>
  );
}

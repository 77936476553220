import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const FooterWrapper = styled.footer`
  padding: 2rem 1rem;
  /* background-color: #011617; */
  background-color: #004643;
  margin-top: 8rem;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;  
  max-width: 78rem;
  margin: 1rem auto;
  padding-bottom: 6rem;
  row-gap: 2rem;
  column-gap: 2rem;
  /* border-bottom: 1px solid #025257; */
`;

const FooterItems = styled.div`
  display: flex;
  flex-direction: column;    
  flex-grow: 1;
  max-width: 16rem;

  .itemHead {
    color: white;
    font-size: 1rem;
    font-weight: 600;
  }

  p {
    color: #acc7ca;
    margin-top: 1.2rem;
    font-size: 0.9rem;    
  }

  a {
    color: #acc7ca;
    text-decoration: none;
    margin-top: 1.2rem;
    font-size: 0.9rem;

    &:hover {
      color: white;
    }
  }
`;

export default function Footer() {
  return (
    <FooterWrapper>
      <ItemsWrapper>
        <StaticImage
          src="../images/logo-white.png"
          alt="A&D logo"
          placeholder="blurred"
          layout="fixed"
          height={40}
          style={{marginRight:"3rem"}}
        />
        <FooterItems>
          <p className="itemHead">Information</p>
          <Link to="../../">Home</Link>
          <Link to="../../about">About us</Link>
          <Link to="/projects">Projects</Link>
          <Link to="/blog">Blog</Link>
        </FooterItems>
        <FooterItems>
          <p className="itemHead">Contact Us</p>
          <a href="https://g.page/asbestosanddemolition?share">
            163 Balmoral Rd W North Vancouver, BC V7N 2T6
          </a>
          <a href="tel:778-340-8795">(+1) 778 340 8795</a>
          <a href="mailto:info@addemolition.com">info@addemolition.com</a>
        </FooterItems>
        <FooterItems>
          <p className="itemHead">About A&D Demolition</p>
          <p>
            A&D Demolition is a North Vancouver based company, that counts with
            over 9 years of experience, the equipment and the team to offer you
            a quality job at a reasonable price, making safety and punctuality
            our priority.
          </p>
        </FooterItems>
      </ItemsWrapper>
    </FooterWrapper>
  );
}

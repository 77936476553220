import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"


export default function Navbar() {
  const [clicked, setClicked] = useState(false)
  const toggle = () => setClicked(!clicked)

  const [dropClicked, setDropClicked] = useState(false)
  const toggleDrop = () => setDropClicked(!dropClicked)

  const useOutsideClick = (ref, callback) => {
    const handleClick = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback()
      }
    }

    useEffect(() => {
      document.addEventListener("click", handleClick)

      return () => {
        document.removeEventListener("click", handleClick)
      }
    })
  }

  const [scroll, setscroll] = useState(false)
  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset > 30) {
        setscroll(true)
      } else {
        setscroll(false)
      }
    }
  })

  const ref = useRef()
  useOutsideClick(ref, () => {
    setDropClicked(false)
  })

  return (
    <header className={`${scroll ? "shadow" : " "}`}>
      <nav
        className={`${clicked ? "topnav responsive" : "topnav"}`}
        role="navigation"
      >
        <Link to="/">
          <img
            className="logo"
            src="/logo-white.png"
            alt="A&D logo"
            placeholder="blurred"
            layout="fixed"
            height="35"
            width="81"
            loading="lazy"
          />
        </Link>
        <div className="nav-items">
          <Link to="../../../contact-us/">Contact Us</Link>
          <Link to="../../../projects">Projects</Link>
          <Link to="../../../blog/">Blog</Link>
          <Link to="../../../markets/">Markets</Link>
          <button className="dropbtn" onClick={toggleDrop} ref={ref}>
            Services
            <img
              src="/icons/dropdown.svg"
              alt="dropdown icon"
              width="15"
              height="15"
            />
          </button>
          <div className="dropdown">
            <div
              className={`${
                dropClicked ? "dropdown-content activated" : "dropdown-content"
              }`}
            >
              <Link to="../../../services/asbestos-removal">
                Asbestos Removal
              </Link>
              <Link to="../../../services/demolition">Demolition Services</Link>
              <Link to="../../../services/hazardous-waste-disposal">
                Hazardous Waste Disposal
              </Link>
            </div>
          </div>
          <Link to="../../../about/">About Us</Link>
          <button className="icon" onClick={toggle}>
            &#9776;
          </button>
        </div>
      </nav>
    </header>
  )
}
